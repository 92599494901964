import {Color} from '../../types/color'

const colors: Color[] = [
  {
    name: 'Red',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: 40.4,
          b: 25.85
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: 52.6,
          b: 33.66
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: 63.27,
          b: 40.49
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: 70.13,
          b: 44.88
        }
      },
      {
        name: '500',
        lab: {
          l: 53.49,
          a: 71.84,
          b: 45.98
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: 58.79,
          b: 37.62
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: 42.61,
          b: 27.27
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: 26.68,
          b: 17.07
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: 14.23,
          b: 9.11
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: 2.08,
          b: 1.38
        }
      }
    ]
  },
  {
    name: 'Orange',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: 23.16,
          b: 31.27
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: 31.24,
          b: 42.18
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: 37.68,
          b: 50.87
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: 41.48,
          b: 56
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: 46.33,
          b: 62.54
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: 45.23,
          b: 61.07
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: 38.24,
          b: 51.63
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: 23.7,
          b: 32
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: 11.3,
          b: 15.27
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: 1.5,
          b: 2.11
        }
      }
    ]
  },
  {
    name: 'Yellow',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: 21.11,
          b: 19.82
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: 20.73,
          b: 32.67
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: 20.03,
          b: 42.58
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: 16.32,
          b: 50.84
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: 9.55,
          b: 58.96
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: 6.26,
          b: 64.09
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: 6.95,
          b: 71.16
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: 7.37,
          b: 75.44
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: 4.22,
          b: 43.23
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: 0.43,
          b: 5.41
        }
      }
    ]
  },
  {
    name: 'Lime Green',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: -18.78,
          b: 29.88
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: -24.1,
          b: 38.35
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: -27.83,
          b: 44.27
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: -32.08,
          b: 51.05
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: -34.75,
          b: 55.28
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: -39.53,
          b: 62.9
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: -42.38,
          b: 67.43
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: -41.9,
          b: 66.66
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: -33.23,
          b: 52.87
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: -11.55,
          b: 18.39
        }
      }
    ]
  },
  {
    name: 'Green',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: -29.93,
          b: 24.68
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: -36.87,
          b: 30.41
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: -43.5,
          b: 35.88
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: -49.57,
          b: 40.89
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: -55.38,
          b: 45.68
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: -60.17,
          b: 49.63
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: -60.24,
          b: 49.69
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: -54.15,
          b: 44.67
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: -41.54,
          b: 34.26
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: -9.07,
          b: 7.48
        }
      }
    ]
  },
  {
    name: 'Teal',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: -22.38,
          b: 5.3
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: -28.21,
          b: 6.68
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: -33.08,
          b: 7.83
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: -37.94,
          b: 8.99
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: -41.84,
          b: 9.91
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: -45.73,
          b: 10.83
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: -51.57,
          b: 12.21
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: -57.41,
          b: 13.6
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: -46.7,
          b: 11.06
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: -10.7,
          b: 2.53
        }
      }
    ]
  },
  {
    name: 'Cyan',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: -13.81,
          b: -7.45
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: -17.33,
          b: -9.35
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: -20.85,
          b: -11.25
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: -24.25,
          b: -13.38
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: -27.01,
          b: -14.58
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: -30.53,
          b: -16.48
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: -33.17,
          b: -17.9
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: -35.81,
          b: -19.33
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: -25.25,
          b: -13.63
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: -5.89,
          b: -3.18
        }
      }
    ]
  },
  {
    name: 'Light Blue',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: -6.57,
          b: -17.07
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: -8.37,
          b: -21.74
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: -10.52,
          b: -27.34
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: -11.1,
          b: -30.33
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: -12.68,
          b: -32.94
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: -14.12,
          b: -36.67
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: -15.2,
          b: -39.47
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: -11.96,
          b: -31.06
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: -6.57,
          b: -17.07
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: -1.18,
          b: -3.07
        }
      }
    ]
  },
  {
    name: 'Blue',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: -2.08,
          b: -24.81
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: -2.58,
          b: -30.79
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: -3,
          b: -35.77
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: -3.42,
          b: -40.74
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: -3.83,
          b: -45.73
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: -4.09,
          b: -48.72
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: -2.97,
          b: -44.8
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: -2.58,
          b: -30.79
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: -1.49,
          b: -17.83
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: -0.24,
          b: -2.88
        }
      }
    ]
  },
  {
    name: 'Indigo',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: 15.87,
          b: -43.7
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: 19.28,
          b: -53.1
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: 20.99,
          b: -57.8
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: 22.01,
          b: -60.62
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: 21.67,
          b: -59.68
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: 20.3,
          b: -55.92
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: 16.21,
          b: -44.64
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: 11.64,
          b: -30.34
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: 5.97,
          b: -16.44
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: 0.85,
          b: -2.34
        }
      }
    ]
  },
  {
    name: 'Purple',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: 46.12,
          b: -57.99
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: 54.21,
          b: -68.16
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: 56.7,
          b: -71.29
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: 55.46,
          b: -69.73
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: 49.86,
          b: -62.68
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: 43.63,
          b: -54.86
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: 35.54,
          b: -44.68
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: 24.96,
          b: -31.38
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: 14.38,
          b: -18.07
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: 2.55,
          b: -3.2
        }
      }
    ]
  },
  {
    name: 'Magenta',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: 48.43,
          b: -35.57
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: 56.84,
          b: -40.94
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: 64.99,
          b: -46.81
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: 71.48,
          b: -51.48
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: 73.11,
          b: -52.65
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: 69.05,
          b: -49.73
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: 60.93,
          b: -43.89
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: 43.89,
          b: -31.61
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: 24.42,
          b: -17.59
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: 4.13,
          b: -2.98
        }
      }
    ]
  },
  {
    name: 'Pink',
    shades: [
      {
        name: '900',
        lab: {
          l: 22,
          a: 43.67,
          b: -4.43
        }
      },
      {
        name: '800',
        lab: {
          l: 31,
          a: 54.61,
          b: -5.54
        }
      },
      {
        name: '700',
        lab: {
          l: 40,
          a: 65.5,
          b: -7.23
        }
      },
      {
        name: '600',
        lab: {
          l: 47,
          a: 73.52,
          b: -7.46
        }
      },
      {
        name: '500',
        lab: {
          l: 54,
          a: 76.5,
          b: -7.76
        }
      },
      {
        name: '400',
        lab: {
          l: 62,
          a: 67.55,
          b: -6.85
        }
      },
      {
        name: '300',
        lab: {
          l: 70,
          a: 52.62,
          b: -5.34
        }
      },
      {
        name: '200',
        lab: {
          l: 79,
          a: 34.72,
          b: -3.52
        }
      },
      {
        name: '100',
        lab: {
          l: 88,
          a: 18.8,
          b: -1.9
        }
      },
      {
        name: '000',
        lab: {
          l: 98,
          a: 2.88,
          b: -0.29
        }
      }
    ]
  }
]

export default colors
